.permissions-grid {
  display: grid;
  gap: 10px 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.permissions-crud {
  display: flex;
}

.permissions-crud > div {
  margin-right: 8px;
}

.user-item-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 0;
}

.user-item-heading {
  padding: 20px;
  font-weight: bold;
  cursor: pointer;
}

.user-item-attr {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.permission-crud-wrapper {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 5px;
}

.permission-crud-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.permission-super-admin {
  margin-bottom: 10px;
}

.user-item-cmds {
  margin-top: 20px;
}

.loading-bar {
  background-color: rgb(201, 235, 201);
  color: rgb(7, 48, 7);
  padding: 8px 20px;
  border-radius: 3px;
  margin: 20px 0 10px;
}

.error-bar {
  background-color: rgb(255, 184, 184);
  color: rgb(59, 15, 10);
  padding: 8px 20px;
  border-radius: 3px;
  margin: 20px 0 10px;
}
