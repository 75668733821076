.user-management-wrapper {
  padding: 8px;
  padding-left: 16px;
  margin-top: 1em;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  min-height: 100vh;
}

.users-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.users-heading h2 {
  margin: 0;
  font-family: PassengerSans, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.03em;
}
