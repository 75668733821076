.add-admin-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 20px;
}

.admin-search-bar {
  display: flex;
  align-items: center;
}

.admin-search-label {
  margin-right: 10px;
}

input {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #999;
  padding: 8px 10px;
  font-size: 14px;
}

input[type="email"], input[type="text"] {
  width: 300px;
}

.loading-bar {
  background-color: rgb(201, 235, 201);
  color: rgb(7, 48, 7);
  padding: 8px 20px;
  border-radius: 3px;
  margin: 10px 0;
}

.error-bar {
  background-color: rgb(255, 184, 184);
  color: rgb(59, 15, 10);
  padding: 8px 20px;
  border-radius: 3px;
  margin: 10px 0;
}

.fetched-user {
  margin: 10px 0 0;
}

.admin-cmds {
  margin-top: 10px;
}
